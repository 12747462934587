"use client"
import { PageLayout } from "../../common/components/layouts/page-layout"

import Userstable from "./users-table"

const ListUsersPage = () => {
  return (
    <PageLayout>
      <Userstable />
    </PageLayout>
  )
}

export default ListUsersPage
