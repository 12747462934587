import type { I18nTranslations } from "@incmix/i18n/src/types/orgs"

export const ptTranslations: I18nTranslations = {
  organizations: {
    title: "Organizações",
    createOrganization: "Criar Organização",
    name: "Nome",
    members: "Membros",
    loading: "Carregando...",
    createNewOrganization: "Criar Nova Organização",
    organizationName: "Nome da Organização",
    create: "Criar",
    creating: "Criando...",
    noOrganizations: "Você ainda não faz parte de nenhuma organização.",
    errorLoading: "Falha ao carregar organizações. Por favor, tente novamente.",
  },
  organizationDetails: {
    notFound: "Organização não encontrada",
    deleteOrganization: "Excluir Organização",
    members: "Membros",
    name: "Nome",
    email: "E-mail",
    role: "Função",
    actions: "Ações",
    newMemberEmail: "E-mail do Novo Membro",
    addMember: "Adicionar Membro",
    cannotRemoveSelf: "Você não pode remover a si mesmo",
    deleteConfirmation: "Tem certeza que deseja excluir {name}?",
    editRole: "Editar função",
    editName: "Editar nome",
    users: "Usuários",
    environmentVariables: "Variáveis de Ambiente",
    success: {
      addMember: "Membro adicionado com sucesso",
      updateMemberRole: "Função do membro atualizada com sucesso",
      deleteOrganization: "Organização excluída com sucesso",
      updateOrganizationName: "Nome da organização atualizado com sucesso",
    },
    error: {
      addMember: "Erro ao adicionar membro",
      updateMemberRole: "Erro ao atualizar função do membro",
      deleteOrganization: "Erro ao excluir organização",
      updateOrganizationName: "Erro ao atualizar nome da organização",
    },
  },
  roles: {
    owner: "Proprietário",
    admin: "Administrador",
    editor: "Editor",
    commenter: "Comentador",
    viewer: "Visualizador",
  },
}
