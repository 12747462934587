export const AUTH_BASE_PATH = "/api/auth"
export const USERS_BASE_PATH = "/api/users"
export const ORG_BASE_PATH = "/api/org"
export const INTL_BASE_PATH = "/api/intl"
export const TODO_BASE_PATH = "/api/tasks"
export const EMAIL_BASE_PATH = "/api/email"
export const FILES_BASE_PATH = "/api/files"
export const LOCATION_BASE_PATH = "/api/location"

export const DEFAULT_LOCALE = "defaultLocale"
export const DEFAULT_MESSAGES = "defaultMessages"

export const ERROR_NOT_IMPL = { namespace: "errors", key: "not_implemented" }
export const ERROR_FORBIDDEN = { namespace: "errors", key: "forbidden" }

// Default Intl Keys

export const ERROR_INVALID_CREDENTIALS = {
  namespace: "errors",
  key: "invalid_credentials",
}
export const ERROR_UNAUTHORIZED = { namespace: "errors", key: "unauthorized" }
export const ERROR_SERVER_ERROR = { namespace: "errors", key: "server_error" }
export const ERROR_BAD_REQUEST = { namespace: "errors", key: "bad_request" }
