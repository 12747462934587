import React from "react"
import type { IconProps } from "./types"

export function Drizzle({ size = "15", ...props }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Drizzle Icon"
      {...props}
    >
      <path
        d="m12.833 17.383.947-1.64c.156-.264.069-.558-.185-.714a.51.51 0 0 0-.713.196l-.948 1.65a.49.49 0 0 0 .196.693.51.51 0 0 0 .703-.185m-2.383-2.158.947-1.641a.516.516 0 0 0-.185-.713.51.51 0 0 0-.713.195l-.947 1.65a.49.49 0 0 0 .195.694.51.51 0 0 0 .703-.185m-3.701 2.158.947-1.631a.504.504 0 0 0-.185-.713.51.51 0 0 0-.713.195l-.948 1.65a.49.49 0 0 0 .196.694.504.504 0 0 0 .703-.195m-2.384-2.149.947-1.64a.514.514 0 0 0-.185-.713.51.51 0 0 0-.713.195l-.957 1.65a.5.5 0 0 0 .205.694.51.51 0 0 0 .703-.186"
        fill="#5AC8F5"
      />
      <path
        d="M4.082 11.68h9.463c2.549 0 4.424-1.934 4.424-4.317 0-2.48-2.012-4.287-4.63-4.287C12.384 1.201 10.577 0 8.4 0 5.49 0 3.087 2.256 2.833 5.098 1.455 5.498.42 6.728.42 8.35c0 1.845 1.338 3.33 3.662 3.33"
        fill="#fff"
      />
    </svg>
  )
}
