import { enTranslations as baseTranslations } from "@incmix/i18n/src/translations/en"
import type { I18nTranslations } from "@incmix/i18n/src/types/core"
import { enTranslations as authTranslations } from "@incmix/pages/src/auth/translations/en"
import { enTranslations as layoutTranslations } from "@incmix/pages/src/common/components/layouts/translations/en"
import { enTranslations as commonTranslations } from "@incmix/pages/src/common/translations/en"
import { enTranslations as dashboardTranslations } from "@incmix/pages/src/dashboard/translations/en"
import { enTranslations as orgsTranslations } from "@incmix/pages/src/orgs/translations/en"
import { enTranslations as userTranslations } from "@incmix/pages/src/user/translations/en"

export const en: I18nTranslations = {
  ...baseTranslations,
  ...layoutTranslations,
  ...commonTranslations,
  ...authTranslations,
  ...orgsTranslations,
  ...userTranslations,
  ...dashboardTranslations,
}
