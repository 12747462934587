import { CardContainer } from "@incmix/ui"
import { FileFolderAccordionTable } from "@incmix/ui"
import { Flex, Text } from "@radix-ui/themes"
import { useTranslation } from "react-i18next"
import { PageLayout } from "../common/components/layouts/page-layout"
import { OrganizationLayout } from "./layouts/organisation-layout"

const OrganizationEnvVarsPage: React.FC = () => {
  const { t } = useTranslation(["organizationDetails"])

  return (
    <PageLayout>
      <OrganizationLayout activeTab="env-vars">
        <CardContainer>
          <Flex direction="column" gap="4">
            <Text size="5" weight="bold">
              {t("environmentVariables")}
            </Text>
            {/* Add environment variables content here */}
            <FileFolderAccordionTable />
          </Flex>
        </CardContainer>
      </OrganizationLayout>
    </PageLayout>
  )
}

export default OrganizationEnvVarsPage
