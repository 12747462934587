import type { I18nTranslations } from "@incmix/i18n/src/types/orgs"

export const enTranslations: I18nTranslations = {
  organizations: {
    title: "Organizations",
    createOrganization: "Create Organization",
    name: "Name",
    members: "Members",
    loading: "Loading...",
    createNewOrganization: "Create New Organization",
    organizationName: "Organization Name",
    create: "Create",
    creating: "Creating...",
    noOrganizations: "You are not a member of any organizations yet.",
    errorLoading: "Failed to load organizations. Please try again.",
  },
  organizationDetails: {
    notFound: "Organization not found",
    deleteOrganization: "Delete Organization",
    members: "Members",
    name: "Name",
    email: "Email",
    role: "Role",
    actions: "Actions",
    newMemberEmail: "New Member Email",
    addMember: "Add Member",
    cannotRemoveSelf: "You cannot remove yourself",
    deleteConfirmation: "Are you sure you want to delete {name}?",
    editRole: "Edit role",
    editName: "Edit name",
    users: "Users",
    environmentVariables: "Environment Variables",
    success: {
      addMember: "Member added successfully",
      updateMemberRole: "Member role updated successfully",
      deleteOrganization: "Organization deleted successfully",
      updateOrganizationName: "Organization name updated successfully",
    },
    error: {
      addMember: "Error adding member",
      updateMemberRole: "Error updating member role",
      deleteOrganization: "Error deleting organization",
      updateOrganizationName: "Error updating organization name",
    },
  },
  roles: {
    owner: "Owner",
    admin: "Admin",
    editor: "Editor",
    commenter: "Commenter",
    viewer: "Viewer",
  },
}
