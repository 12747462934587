import { ptTranslations as baseTranslations } from "@incmix/i18n/src/translations/pt"
import type { I18nTranslations } from "@incmix/i18n/src/types/core"
import { ptTranslations as authTranslations } from "@incmix/pages/src/auth/translations/pt"
import { ptTranslations as layoutTranslations } from "@incmix/pages/src/common/components/layouts/translations/pt"
import { ptTranslations as commonTranslations } from "@incmix/pages/src/common/translations/pt"
import { ptTranslations as dashboardTranslations } from "@incmix/pages/src/dashboard/translations/pt"
import { ptTranslations as orgsTranslations } from "@incmix/pages/src/orgs/translations/pt"
import { ptTranslations as userTranslations } from "@incmix/pages/src/user/translations/pt"

export const pt: I18nTranslations = {
  ...baseTranslations,
  ...layoutTranslations,
  ...commonTranslations,
  ...authTranslations,
  ...orgsTranslations,
  ...userTranslations,
  ...dashboardTranslations,
}
