import "@incmix/tw/src/styles/global.css"
import React from "react"
import ReactDOM from "react-dom/client"
import "./instrument"
import App from "./App"
import "@incmix/i18n"
import { NuqsReactAdaptor } from "@incmix/tw"
import QueryProvider from "./query-client"

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryProvider>
      <NuqsReactAdaptor>
        <App />
      </NuqsReactAdaptor>
    </QueryProvider>
  </React.StrictMode>
)
