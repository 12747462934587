import { Text } from "@radix-ui/themes"
import type React from "react"

const TestPage: React.FC = () => {
  return (
    <>
      {/* Swap this out for any component or page you want to test */}
      <Text>Test Page</Text>
    </>
  )
}

export default TestPage
