import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components"
import { I18n, usei18n } from "@incmix/i18n"
import LoadingPage from "@incmix/pages/src/common/loading-page"
import { type Language, useLanguageStore, useThemeStore } from "@incmix/store"
import { Theme } from "@radix-ui/themes"
import { useQuery } from "@tanstack/react-query"
import { RouterProvider } from "@tanstack/react-router"
import { Settings } from "luxon"
import React, { Suspense, useEffect } from "react"
import { router } from "./instrument"
import { translations } from "./translations"

const luxonLocale: Record<Language, string> = {
  en: "en",
  pt: "pt-BR",
}

function App() {
  const { theme } = useThemeStore()
  const { language } = useLanguageStore()
  useQuery({
    queryKey: ["translations"],
    queryFn: () => {
      usei18n(translations)
      return ""
    },
  })

  useEffect(() => {
    if (language) {
      I18n.changeLanguage(language)
      Settings.defaultLocale = luxonLocale[language]
    }
  }, [language])

  return (
    <Theme
      accentColor="blue"
      grayColor="slate"
      panelBackground="solid"
      scaling="100%"
      radius="large"
      appearance={theme}
    >
      <Suspense fallback={<LoadingPage />}>
        <FluentProvider
          theme={theme === "light" ? webLightTheme : webDarkTheme}
        >
          <RouterProvider router={router} />
        </FluentProvider>
      </Suspense>
    </Theme>
  )
}

export default App
