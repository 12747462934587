import React from "react"
import type { IconProps } from "./types"

export function Snow({ size = "15", ...props }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Snow Icon"
      {...props}
    >
      <path
        d="M6.846 15.606c.39 0 .615-.264.615-.645v-1.484l1.172.654c.322.185.654.156.84-.166.146-.274.058-.606-.274-.8l-1.738-.977v-.84l-.186-2.793 2.344 1.552.723.42-.02 1.993c0 .38.235.644.547.644.381 0 .567-.273.567-.644l.01-1.368 1.308.762c.322.195.654.117.84-.205.185-.332.088-.674-.235-.86l-1.298-.742 1.172-.693c.312-.185.449-.488.273-.8-.166-.284-.518-.352-.84-.167l-1.709 1.016-.732-.42-2.52-1.24 2.52-1.24.722-.42 1.719 1.015c.322.186.684.117.84-.156.176-.322.039-.625-.274-.81l-1.171-.694 1.298-.742c.332-.195.43-.518.235-.86-.176-.312-.508-.39-.84-.205l-1.309.743-.01-1.358c0-.361-.185-.635-.556-.635-.322 0-.557.264-.557.635l.02 1.992-.723.43-2.344 1.553.186-2.803v-.83l1.738-.977c.332-.195.42-.527.274-.8-.186-.323-.518-.352-.84-.176l-1.172.664V.645C7.461.264 7.236 0 6.846 0c-.362 0-.606.264-.606.645v1.484l-1.19-.664c-.322-.176-.635-.147-.83.176-.156.273-.05.605.273.79l1.748.987v.83l.176 2.803-2.334-1.553-.732-.43.02-1.992c0-.37-.235-.635-.548-.635-.37 0-.566.274-.566.635l-.01 1.358-1.3-.744c-.322-.175-.674-.107-.85.205-.195.342-.087.674.235.86l1.299.742-1.172.693c-.312.186-.45.47-.273.811.156.273.507.342.84.156l1.718-1.006.723.41 2.52 1.24-2.52 1.241-.723.42-1.719-1.016c-.322-.185-.673-.117-.82.157-.185.341-.058.625.254.81l1.172.693-1.299.743c-.322.185-.42.537-.234.859s.527.38.85.205l1.298-.742.01 1.348c0 .37.195.644.566.644.313 0 .547-.264.547-.644l-.01-1.993.723-.41 2.334-1.562-.176 2.793v.84l-1.748.976c-.322.195-.43.527-.273.8.195.323.508.352.83.167l1.191-.654v1.484c0 .38.244.644.606.644"
        fill="#fff"
      />
    </svg>
  )
}
