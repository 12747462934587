import {
  EmailVerificationRoute,
  GoogleAuthCallbackRoute,
  ListUsersRoute,
  LoginRoute,
  SignupRoute,
  TauriGoogleAuthCallbackRoute,
  WelcomeRoute,
} from "@incmix/pages"
import {
  IndexRoute,
  LoadingRoute,
  NotFoundRoute,
  RootRoute,
  TestRoute,
} from "@incmix/pages"
import {
  OrganisationDetailsRoute,
  OrganisationEnvVarsRoute,
  OrganisationUsersRoute,
  OrganisationsRoute,
} from "@incmix/pages"
import { DashboardRoute } from "@incmix/pages"
import { NotificationsRoute, ProfileRoute, SettingsRoute } from "@incmix/pages"
import * as Sentry from "@sentry/react"
import { createRouter } from "@tanstack/react-router"
import { useEffect } from "react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

const routeTree = RootRoute.addChildren([
  LoginRoute,
  EmailVerificationRoute,
  SignupRoute,
  IndexRoute,
  WelcomeRoute,
  GoogleAuthCallbackRoute,
  TauriGoogleAuthCallbackRoute,
  ProfileRoute,
  SettingsRoute,
  TestRoute,
  OrganisationsRoute,
  OrganisationDetailsRoute,
  OrganisationUsersRoute,
  OrganisationEnvVarsRoute,
  NotificationsRoute,
  NotFoundRoute,
  LoadingRoute,
  DashboardRoute,
  ListUsersRoute,
])

export const router = createRouter({ routeTree })

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.httpClientIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,
  tracePropagationTargets: [import.meta.env.VITE_BFF_API_URL || ""],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
