"use client"

import { RootRoute } from "@common"
import { createRoute } from "@tanstack/react-router"
import { z } from "zod"
import ListUsersPage from "../list-users-page"

const searchParamsSchema = z.object({
  id: z.string().optional(),
  fullName: z.string().optional(),
  email: z.string().optional(),
  localeId: z.string().optional(),
  page: z.number().optional(),
  pageSize: z.number().optional(),
  sort: z
    .object({
      id: z.string(),
      desc: z.boolean(),
    })
    .array()
    .optional(),
})
export type UserListSearchParams = z.infer<typeof searchParamsSchema>
export default createRoute({
  getParentRoute: () => RootRoute,
  path: "/users/list",
  component: () => <ListUsersPage />,
  validateSearch: searchParamsSchema,
})
