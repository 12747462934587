import type { I18nTranslations } from "@incmix/i18n/src/types/auth"

export const enTranslations: I18nTranslations = {
  login: {
    title: "Log-In",
    emailValidation: "Please provide a valid email",
    passwordValidation: "Password must be at least 6 characters long",
    submit: "Login",
    loggingIn: "Logging in...",
    googleLogin: "Login with Google",
    signupPrompt: "Don't have an account? Sign up",
    forgotPassword: "Forgot Password?",
    redirected: "You'll be redirected to the app.",
    closeWindow: "You may close this window.",
    error: {
      googleAuthUrl: "Failed to retrieve authentication URL",
      googleAuth: "Failed to authenticate with Google",
      login: "Error logging in",
      logout: "Error logging out",
    },
    success: {
      login: "Login successful",
      logout: "Logout successful",
    },
  },
  signup: {
    title: "Sign-Up",
    fullNameValidation: "Please enter your Name",
    emailValidation: "Please provide a valid email",
    passwordValidation: "Password must be at least 6 characters long",
    submit: "Sign Up",
    signingUp: "Signing up...",
    signupSuccess: "Signup successful",
    loginPrompt: "Already have an account? Log in",
    error: {
      signup: "Error signing up",
    },
  },
  emailVerification: {
    title: "Email Verification",
    verifying: "Verifying",
    verified: "Verified",
  },
}
