import { useSidebarStore } from "@incmix/store"
import { ReactiveButton } from "@incmix/ui"
import {
  BackpackIcon,
  ChevronDownIcon,
  ComponentInstanceIcon,
  CubeIcon,
  DashboardIcon,
  EnvelopeClosedIcon,
  FileTextIcon,
  LockClosedIcon,
  PersonIcon,
  PinLeftIcon,
  PinRightIcon,
  QuestionMarkCircledIcon,
  ReaderIcon,
} from "@radix-ui/react-icons"
import { Box, DropdownMenu, Flex, Text } from "@radix-ui/themes"
import { Link as RouterLink } from "@tanstack/react-router"
import React, {
  type MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"

const MINIFIED_WIDTH = "w-14"

export function Sidebar() {
  const { state, isMinified, toggleMinified } = useSidebarStore()
  const { t } = useTranslation("sidebar")

  const sidebarWidth = isMinified
    ? MINIFIED_WIDTH
    : state === "extended"
      ? "w-sidebar"
      : "w-0"

  return (
    <Box
      asChild
      className={`select-none overflow-hidden bg-gray-3 transition-[width] duration-300 ease-in-out ${sidebarWidth}`}
    >
      <aside className="border-gray-6 border-r bg-gray-3">
        <Box className="h-full w-sidebar bg-gray-3">
          <Flex direction="column" gap="1" p="2">
            <SidebarItem
              icon={<DashboardIcon />}
              label={t("dashboard")}
              to="/dashboard"
            />
            <SidebarItem
              icon={<EnvelopeClosedIcon />}
              label={t("inbox")}
              to="/inbox"
              badge={3}
            />
            <SidebarItem
              icon={<BackpackIcon />}
              label={t("ecommerce")}
              to="/e-commerce"
            />
            <SidebarItem icon={<PersonIcon />} label={t("users")} to="/users">
              <SidebarSubItem label={t("usersList")} to="/users/list" />
              <SidebarSubItem label={t("profile")} to="/profile" />
              <SidebarSubItem label={t("feed")} to="/users/feed" />
              <SidebarSubItem label={t("settings")} to="/settings" />
            </SidebarItem>
            <SidebarItem
              icon={<FileTextIcon />}
              label={t("pages")}
              to="/pages"
            />
            <SidebarItem
              icon={<LockClosedIcon />}
              label={t("authentication")}
              to="/auth"
            />
            <SidebarItem
              icon={<CubeIcon />}
              label={t("organizations")}
              to="/organizations"
            />
            <SidebarItem icon={<ReaderIcon />} label={t("docs")} to="/docs" />
            <SidebarItem
              icon={<ComponentInstanceIcon />}
              label={t("components")}
              to="/components"
            />
            <SidebarItem
              icon={<QuestionMarkCircledIcon />}
              label={t("help")}
              to="/help"
            />
          </Flex>
          <Box
            className={`fixed bottom-4 transition-all duration-300 ease-in-out ${
              isMinified ? "translate-x-5" : "translate-x-[13.5rem]"
            }`}
          >
            <ReactiveButton
              variant="ghost"
              onClick={toggleMinified}
              className="h-6 w-4 rounded text-gray-11 transition-colors duration-300 hover:bg-gray-7"
            >
              {isMinified ? (
                <PinRightIcon className="h-6 w-6" />
              ) : (
                <PinLeftIcon className="h-6 w-6" />
              )}
            </ReactiveButton>
          </Box>
        </Box>
      </aside>
    </Box>
  )
}

function SidebarItem({
  icon,
  label,
  to,
  badge,
  children,
}: {
  icon: React.ReactNode
  label: string
  to: string
  badge?: number
  children?: React.ReactNode
}) {
  const { currentlyExpanded, isMinified } = useSidebarStore()
  const [expanded, setExpanded] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState<number | undefined>(0)

  useEffect(() => {
    if (isMinified && expanded) {
      setExpanded(false)
    }
  }, [isMinified, expanded])

  useEffect(() => {
    if (isMinified) {
      setContentHeight(0)
    } else if (contentRef.current) {
      setContentHeight(expanded ? contentRef.current.scrollHeight : 0)
    }
  }, [expanded, isMinified])

  const toggleExpand: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (children) {
      e.preventDefault()
      if (!isMinified) {
        setExpanded(!expanded)
      }
    }
  }

  const isSidebarExpanded = currentlyExpanded()
  const showFullLabel = !isMinified && isSidebarExpanded

  const renderIcon = () => (
    <Box className="flex h-6 w-6 items-center justify-center">{icon}</Box>
  )

  const renderLabel = () => (
    <Text ml="3" className="flex-grow">
      {label}
    </Text>
  )

  const renderBadge = () =>
    badge && (
      <Box className="ml-auto rounded-full bg-blue-9 px-2 py-1 font-semibold text-white text-xs">
        {badge}
      </Box>
    )

  const renderExpandIcon = () =>
    children && (
      <ChevronDownIcon
        color="gray"
        className={`ml-2 h-4 w-4 transition-transform duration-200 ${expanded ? "rotate-180" : ""}`}
      />
    )

  const renderChildren = () =>
    children && (
      <Box
        ref={contentRef}
        className="overflow-hidden transition-all duration-300 ease-in-out"
        style={{ maxHeight: contentHeight }}
      >
        <Flex
          direction="column"
          gap="1"
          ml={showFullLabel ? "6" : "0"}
          mt="2"
          mb="2"
        >
          {React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              const { label, to } = child.props
              return <SidebarSubItem key={to} label={label} to={to} />
            }
            return child
          })}
        </Flex>
      </Box>
    )

  const renderItem = () => (
    <Flex
      align="center"
      className={`h-10 cursor-pointer rounded transition-all duration-300 ease-in-out ${
        isMinified ? "w-10 px-2" : "mx-2 px-2"
      } hover:bg-gray-7`}
    >
      <Box className="flex items-center">{renderIcon()}</Box>
      <Box
        className={`flex flex-1 items-center overflow-hidden transition-[width,opacity] duration-300 ease-in-out ${
          isMinified ? "w-0 opacity-0" : "w-auto opacity-100"
        }`}
      >
        {renderLabel()}
        {renderBadge()}
        {renderExpandIcon()}
      </Box>
    </Flex>
  )

  return (
    <DropdownMenu.Root>
      {children ? (
        <DropdownMenu.Trigger
          disabled={!isMinified}
          onClick={isMinified ? undefined : toggleExpand}
        >
          <Box>
            {renderItem()}
            {!isMinified && renderChildren()}
          </Box>
        </DropdownMenu.Trigger>
      ) : (
        <RouterLink to={to}>
          <Box>{renderItem()}</Box>
        </RouterLink>
      )}
      <DropdownMenu.Content>
        <Flex align="center" justify={"center"} className="px-4 py-2">
          {renderIcon()}
          <Text mx="2">{label}</Text>
        </Flex>
        <DropdownMenu.Separator />
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            const { label, to } = child.props
            return (
              <DropdownMenu.Item>
                <RouterLink to={to} className="w-full">
                  <Flex align="center" className="px-4 py-2">
                    <Text>{label}</Text>
                  </Flex>
                </RouterLink>
              </DropdownMenu.Item>
            )
          }
          return child
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}

function SidebarSubItem({
  label,
  to,
}: {
  label: string
  to: string
}) {
  const { currentlyExpanded, isMinified } = useSidebarStore()
  const initials = label
    .split(" ")
    .map((word) => word[0])
    .join("")
    .slice(0, 2)
    .toUpperCase()
    .slice(0, 2)

  const isSidebarExpanded = currentlyExpanded()
  const showFullLabel = isSidebarExpanded && !isMinified
  return (
    <RouterLink
      to={to}
      className={`block rounded py-2 text-gray-12 transition-all duration-300 ease-in-out ${showFullLabel ? "px-4" : "px-0"} hover:bg-gray-7`}
    >
      <Flex align="center">
        {showFullLabel ? (
          <Text>{label}</Text>
        ) : (
          <Box className="flex h-6 w-6 items-center justify-center">
            {initials}
          </Box>
        )}
      </Flex>
    </RouterLink>
  )
}
