import React from "react"
import type { IconProps } from "./types"

export function HeavyRain({ size = "15", ...props }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Heavy Rain Icon"
      {...props}
    >
      <path
        d="m11.846 17.412 2.207-3.828a.49.49 0 0 0-.185-.693c-.254-.137-.557-.079-.703.185l-2.188 3.809a.5.5 0 0 0 .176.713.5.5 0 0 0 .693-.186m-3.027-.01 2.197-3.799a.5.5 0 0 0-.185-.712c-.264-.147-.557-.079-.713.195L7.92 16.904a.497.497 0 0 0 .196.694.504.504 0 0 0 .703-.196m-3.047 0 2.197-3.799a.5.5 0 0 0-.185-.712c-.264-.147-.557-.079-.713.195l-2.198 3.818a.497.497 0 0 0 .196.694.504.504 0 0 0 .703-.196m-3.047 0 2.197-3.799a.5.5 0 0 0-.185-.712c-.264-.147-.557-.079-.713.195l-2.197 3.818a.497.497 0 0 0 .195.694.504.504 0 0 0 .703-.196"
        fill="#5AC8F5"
      />
      <path
        d="M4.082 11.68h9.463c2.549 0 4.424-1.934 4.424-4.317 0-2.48-2.012-4.287-4.63-4.287C12.384 1.201 10.577 0 8.4 0 5.49 0 3.087 2.256 2.833 5.098 1.455 5.498.42 6.728.42 8.35c0 1.845 1.338 3.33 3.662 3.33"
        fill="#fff"
      />
    </svg>
  )
}
