import { useEffect, useRef } from "react"
import { create } from "zustand"

export type TabsStore = {
  isActive: boolean
  setToggleActive: (isActive: boolean) => void
}
export const useTabsStore = create((set) => ({
  isActive: false,
  setToggleActive: (isActive: boolean) => set(() => ({ isActive })),
}))
document.addEventListener("visibilitychange", () => {
  if (document.visibilityState === "visible") {
    // console.log("Tab is active")
  } else {
    // console.log("Tab is inactive")
  }
})

type UseTabCloseCallback = () => void
export const BEFOREUNLOAD = "beforeunload"
export function useTabClose(callback: UseTabCloseCallback): void {
  const callbackRef = useRef<UseTabCloseCallback>(callback)
  useEffect(() => {
    callbackRef.current = callback
  }, [callback])
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
      event.preventDefault()
      event.returnValue = "" // Cancel the event
      callbackRef.current()
    }
    window.addEventListener(BEFOREUNLOAD, handleBeforeUnload)
    return () => {
      window.removeEventListener(BEFOREUNLOAD, handleBeforeUnload)
    }
  }, [])
}
