import {
  type ColumnDef,
  DataTableColumnHeader,
  type DataTableRowAction,
} from "@incmix/tw"
import type { UserProfile } from "@incmix/types"
import { Button } from "@incmix/ui"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { Checkbox, DropdownMenu } from "@radix-ui/themes"

type GetColumnsProps = {
  setRowAction: React.Dispatch<
    React.SetStateAction<DataTableRowAction<UserProfile> | null>
  >
}

export function getColumns({
  setRowAction,
}: GetColumnsProps): ColumnDef<UserProfile>[] {
  return [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-0.5"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="translate-y-0.5"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Id" />
      ),
    },
    {
      accessorKey: "fullName",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Full Name" />
      ),
    },
    {
      accessorKey: "email",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Email" />
      ),
    },
    {
      accessorKey: "localeId",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Locale Id" />
      ),
    },
    {
      id: "actions",
      cell: function Cell({ row }) {
        return (
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button
                aria-label="Open menu"
                variant="ghost"
                className="flex size-8 p-0 data-[state=open]:bg-muted"
              >
                <DotsHorizontalIcon className="size-4" color="black" />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content align="end" className="w-40">
              <DropdownMenu.Item
                onSelect={() => setRowAction({ row, type: "update" })}
              >
                Edit
              </DropdownMenu.Item>
              <DropdownMenu.Separator />
              <DropdownMenu.Item
                // Temp fix for dialog bug
                // https://github.com/radix-ui/themes/issues/155
                onSelect={() =>
                  setTimeout(() => setRowAction({ row, type: "delete" }))
                }
              >
                Delete
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        )
      },
      size: 40,
    },
  ]
}
