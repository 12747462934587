import {
  DataTable,
  type DataTableFilterField,
  type DataTableRowAction,
  DataTableToolbar,
  useDataTable,
} from "@incmix/tw"
import { useQuery } from "@tanstack/react-query"
import React from "react"
import { PageLayout } from "../../common/components/layouts/page-layout"
import { DeleteDialog } from "./delete-dialog"
import { ToolbarActions } from "./toolbar-actions"
;("use client")

import { USERS_BASE_PATH } from "@incmix/hono-shared/utils"
import type { UserProfile } from "@incmix/types"
import { Spinner } from "@incmix/ui"
import { ListUsersRoute } from "../routes"
import type { UserListSearchParams } from "../routes/list-users"
import { getColumns } from "./user-columns"

const BFF_API_URL = import.meta.env["VITE_BFF_API_URL"]

const USERS_API_URL = `${BFF_API_URL}${USERS_BASE_PATH}`
const Userstable = () => {
  const [rowAction, setRowAction] =
    React.useState<DataTableRowAction<UserProfile> | null>(null)

  const columns = React.useMemo(() => getColumns({ setRowAction }), [])

  const filterFields: DataTableFilterField<UserProfile>[] = [
    {
      id: "fullName",
      label: "Full Name",
      placeholder: "Filter Full Name...",
    },
  ]

  const { page, pageSize, fullName, sort } =
    ListUsersRoute.useSearch() as UserListSearchParams

  const { data, isLoading, isFetching } = useQuery<{
    results: UserProfile[]
    metadata: {
      hasNextPage: boolean
      hasPrevPage: boolean
      page: number
      pageCount: number
      pageSize: number
      total: number
    }
  }>({
    queryKey: ["user-list", fullName, sort, page, pageSize],
    placeholderData: (prev) => prev,
    queryFn: async () => {
      const searchParams = new URLSearchParams()
      if (pageSize) searchParams.append("pageSize", String(pageSize))
      if (page) searchParams.append("page", String(page))
      if (fullName)
        searchParams.append("fullName", `ilike:${fullName.replace("+", " ")}`)
      if (sort) {
        const qs = sort
          .map((s) => {
            const op = s.desc ? "-" : "+"
            return `${op}${s.id}`
          })
          .join(",")
        searchParams.append("sort", qs)
      }

      const res = await fetch(
        `${USERS_API_URL}/list?${searchParams.toString()}`,
        {
          credentials: "include",
        }
      )
      return await res.json()
    },
  })

  const { table } = useDataTable({
    data: data?.results ?? [],
    columns,
    pageCount: data?.metadata?.pageCount ?? 1,
    filterFields,
    enableAdvancedFilter: false,
    initialState: {
      sorting: [{ id: "id", desc: false }],
      columnPinning: { right: ["actions"] },
    },
    getRowId: (originalRow) => originalRow.id,
    shallow: false,
    clearOnDefault: true,
  })

  if (isLoading && !data) return <Spinner />
  return (
    <div>
      <DataTable table={table}>
        <DataTableToolbar table={table} filterFields={filterFields}>
          {isFetching && <Spinner className="mr-auto" />}
          <ToolbarActions table={table} />
        </DataTableToolbar>
      </DataTable>
      <DeleteDialog
        open={rowAction?.type === "delete"}
        onOpenChange={() => setRowAction(null)}
        items={rowAction?.row.original ? [rowAction?.row.original] : []}
        showTrigger={false}
        onSuccess={() => rowAction?.row.toggleSelected(false)}
      />
    </div>
  )
}

export default Userstable
